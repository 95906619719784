/*jshint esversion: 6 */
import {
    addActionClass,
    addErrorClass,
    addLoadingClass,
    addSuccessClass,
    removeLoadingClass
} from "./utilities/updateClasses";
import {itemcardSubscriptions} from "./subscriptions";
import {hideIconbarHover, initIconbarHoverClose, showIconbarHover} from "./iconbarHover";
import {initCustomSelects} from "./customSelect";
import {initToggleSlide} from "./toggle";
import {XhrService} from "../ts/xhr.service";
import {appendScriptToDOM} from "./utilities/functions";
import {initFormSubmitChanges} from "./actionClick";
import {updateItemlistStickyOrderBarCount} from "../../../spGlobal/js/shop/itemlistStickyOrderBar";
import {setDot} from "../../../spGlobal/js/setDot";
import {triggerModal} from "./modal";

function updateBasketCount(doc) {
    for (let basketCount of document.querySelectorAll('.js-basketCount')) {
        let firstNewBasketCount = doc.querySelector('.js-basketCount');
        if (firstNewBasketCount) {
            let count = parseInt(firstNewBasketCount.innerText);
            if (isNaN(count)) {
                count = 0;
            }
            if (count === 0) {
                basketCount.style.display = "none";
            } else {
                addActionClass(basketCount, function () {
                    basketCount.style.display = "";
                });
            }
        }
    }
}

function updateItem(target, doc) {
    let dataItemNo = target.dataset.itemNo;
    if (dataItemNo) {
        let addOn = target.closest(".js-updateItem");
        if (addOn) {
            let newTarget = doc.querySelector('.js-addListItemToBasket[data-item-no="' + dataItemNo + '"]');
            if (newTarget) {
                let newaddOn = newTarget.closest('.js-updateItem');
                if (newaddOn) {
                    addOn.innerHTML = newaddOn.innerHTML;
                } else {
                    console.error("cannot update orderbutton");
                }
            }
        }
    }
}

function updateOrderButtonItemcard(doc) {
    let itemcardBuybox = document.querySelector(".itemcardBuybox");
    let newItemcardBuybox = doc.querySelector('.itemcardBuybox');

    if (itemcardBuybox) {
        itemcardBuybox.innerHTML = newItemcardBuybox.innerHTML;

        itemcardSubscriptions(itemcardBuybox);
        initCustomSelects(itemcardBuybox.querySelectorAll(".js-customSelect"));
    }
}

function updateHeaderBasket(doc) {
    let delayBasketHover = 6000;
    if(GLOBALS){
        if(GLOBALS.delayHideBasketHover){
            delayBasketHover = GLOBALS.delayHideBasketHover;
        }
    }
    for (let headerBasket of document.querySelectorAll('.js-headerBasket')) {
        let newHeaderBasket = doc.querySelector('.js-headerBasket');
        if (newHeaderBasket) {
            headerBasket.innerHTML = newHeaderBasket.innerHTML;
            headerBasket.classList.add("showIconbarHover");
            showIconbarHover(headerBasket);
            initIconbarHoverClose(headerBasket.querySelectorAll(".iconbarHover__close"));
            let basketCount = headerBasket.querySelector('.js-basketCount');

            if (basketCount) {
                addActionClass(basketCount);
            }

            setTimeout(function () {
                hideIconbarHover(headerBasket);
            }, delayBasketHover);
        }
    }
}

function updateHeaderEnquiryBasket(doc) {

    for (let headerBasket of document.querySelectorAll('.js-headerEnquiryBasket')) {
        let newHeaderBasket = doc.querySelector('.js-headerEnquiryBasket');
        if (newHeaderBasket) {
            headerBasket.innerHTML = newHeaderBasket.innerHTML;
            let basketCount = headerBasket.querySelector('.js-enquiryBasketCount');

            if (basketCount) {
                addActionClass(basketCount);
            }
        }
    }
}

function eventListenerBasketViewQuantities(basketView, updateTimeout) {
    for (let quantity of basketView.querySelectorAll('.js-itemBasketList .quantity input[type="number"]')) {
        let form = quantity.closest(".js-itemBasketList");
        quantity.addEventListener("change", function () {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(function () {
                sendBasketForm(form, basketView);
            }, 1000);
        });
        quantity.addEventListener("keyup", function (e) {
            if(e.key === "Enter"){
                sendBasketForm(form, basketView);
            }
        });
    }
}

function eventListenerBasketViewQuantitySpinners(basketView, updateTimeout) {
    for (let spinner of basketView.querySelectorAll('.js-itemBasketList .quantity .js-spinnerUp,.js-itemBasketList .quantity .js-spinnerDown')) {
        let form = spinner.closest(".js-itemBasketList");
        spinner.addEventListener("click", function () {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(function () {
                sendBasketForm(form, basketView);
            }, 1000);
        });
    }
}

function eventListenerBasketViewReferenceInputs(basketView, updateTimeout) {
    for (let referenceInput of basketView.querySelectorAll('.js-referenceInput')) {
        let form = referenceInput.closest(".js-itemBasketList");
        referenceInput.addEventListener("change", function () {
            clearTimeout(updateTimeout);
            updateTimeout = setTimeout(function () {
                sendBasketForm(form, basketView);
            }, 1000);
        });
    }
}

function updateAllBasketViews(basketView, newBasketView) {
    if (basketView && newBasketView) {
        let oldList = basketView.querySelector('.js-itemBasketList');
        let oldOrderBox = basketView.querySelector('.js-basketOrderBox');
        let oldSummary = basketView.querySelector('.js-basketSummary');

        let newList = newBasketView.querySelector('.js-itemBasketList');
        let newOrderBox = newBasketView.querySelector('.js-basketOrderBox');
        let newSummary = newBasketView.querySelector('.js-basketSummary');

        if (newList) {
            oldList.innerHTML = newList.innerHTML;
            removeLoadingClass(oldList);
            eventListenerBasketViewQuantities(basketView);
            eventListenerBasketViewQuantitySpinners(basketView);
            eventListenerBasketViewReferenceInputs(basketView);
            triggerModal(basketView.querySelectorAll('[data-modal]'));
        } else {
            basketView.innerHTML = newBasketView.innerHTML;
            return;
        }

        if (newOrderBox) {
            oldOrderBox.innerHTML = newOrderBox.innerHTML;
            removeLoadingClass(oldOrderBox);
            initToggleSlide(oldOrderBox.querySelectorAll(".js-toggleLinkSlide"));
            initFormSubmitChanges();
        }
        if (newSummary) {
            oldSummary.innerHTML = newSummary.innerHTML;
        }

        for(let quantityInput of document.querySelectorAll('.js-quantity-value')){
            setDot(quantityInput);
        }
    }
}

function sendBasketForm(form, basketView) {
    let formData = new FormData(form);
    addLoadingClass(basketView.querySelector('.js-itemBasketList'));
    addLoadingClass(basketView.querySelector('.js-basketOrderBox'));
    fetch(form.action, {
        method: "POST",
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                throw "Could not save data";
            }
            return response.text();
        })
        .then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            updateAllBasketViews(basketView, doc.getElementById('userBasket'));
        })
        .catch((e) => {
            console.error('Error:', e);
        });
}

export function triggerBasketBtns() {
    document.addEventListener("click", function (e) {
        let target = e.target;
        for(let input of document.querySelectorAll('.js-quantity-value')){
            input.setCustomValidity('');
        }

        if (target.closest(".js-addListItemToBasket")) {
            target = target.closest(".js-addListItemToBasket");
            let form = target.closest("form");
            let orderButtonWrapper = target.closest(".orderButtonWrapper");
            let quantityInput = orderButtonWrapper.querySelector('.js-quantity-value');

            if (form && quantityInput) {
                if (quantityInput.value > 0) {
                    addLoadingClass(target);
                    let formData = new FormData(form);
                    let action = target.dataset.action;
                    formData.set('action', action);
                    XhrService.generalAjaxCall('cart', formData, 'POST', 'json').then(response => {
                        if (!response.status) {
                            throw "Could not save data";
                        }
                        const headerBasket = document.querySelector('.js-headerBasket');
                        if (headerBasket && response.headerBasketContent) {
                            var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                            updateHeaderBasket(doc);
                        }
                        if (response.tagManager) {
                            appendScriptToDOM(response.tagManager, true);
                        }
                        form.reset();
                        updateItemlistStickyOrderBarCount();
                        addSuccessClass(target);
                        return response.status;
                    })
                        .catch((e) => {
                            addErrorClass(target);
                            console.error('Error:', e);
                            console.error('Action:', form.action);
                            console.error(formData);
                        });
                }else{
                    quantityInput.focus();
                    quantityInput.select();
                    quantityInput.setCustomValidity(GLOBALS.tc.pleaseEnterQuantity);
                    form.reportValidity();
                }

            }
            e.preventDefault();
        }


        if (target.closest(".js-addToBasketItemcard")) {
            target = target.closest(".js-addToBasketItemcard");
            let form = target.closest("form");
            let orderButtonWrapper = target.closest(".orderButtonWrapper");
            let quantityInput = orderButtonWrapper.querySelector('.js-quantity-value');
            let customizableInputValue = form.dataset.customized;
            let quantityValue = 0;

            if ((form && quantityInput) || (form && customizableInputValue === '1'))  {
                if (quantityInput) {
                    quantityValue = quantityInput.value;
                }
                if (quantityValue > 0 || customizableInputValue === '1') {
                    addLoadingClass(target);
                    let fn = 'cart';
                    let formData = new FormData(form);
                    let action = target.dataset.action;
                    formData.set('action', action);
                    if (customizableInputValue === '1') {
                        fn = 'cartCustomize';
                    }

                    XhrService.generalAjaxCall(fn, formData, 'POST', 'json').then(response => {
                        if (!response.status) {
                            throw "Could not save data";
                        }
                        const headerBasket = document.querySelector('.js-headerBasket');
                        if (headerBasket && response.headerBasketContent) {
                            var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                            updateHeaderBasket(doc);
                        }
                        if (response.tagManager) {
                            appendScriptToDOM(response.tagManager, true);
                        }
                        form.reset();
                        addSuccessClass(target);
                        return response.status;
                    })
                        .catch((e) => {
                            addErrorClass(target);
                            console.error('Error:', e);
                        });
                }
            }
            e.preventDefault();
        }


        if(target.closest(".js-addListItemToInquiryBasket")){
            target=target.closest(".js-addListItemToInquiryBasket");
            let form=target.closest("form");
            let orderButtonWrapper=target.closest(".orderButtonWrapper");
            let quantityInput=orderButtonWrapper.querySelector('.js-quantity-value');

            if(form&&quantityInput){
                if(quantityInput.value>0){
                    addLoadingClass(target);
                    let formData=new FormData(form);
                    let action=target.dataset.action;
                    formData.set('action',action);
                    XhrService.generalAjaxCall('enquiryCart',formData,'POST','json').then(response=>{
                        if(!response.status){
                            throw"Couldnotsavedata";
                        }
                        const headerBasket=document.querySelector('.js-headerEnquiryBasket');
                        if(headerBasket && response.headerEnquiryBasketContent){
                            var doc= new DOMParser().parseFromString(response.headerEnquiryBasketContent,"text/html");
                            updateHeaderEnquiryBasket(doc);
                        }
                        if(response.tagManager){
                            appendScriptToDOM(response.tagManager,true);
                        }
                        form.reset();
                        updateItemlistStickyOrderBarCount();
                        addSuccessClass(target);
                        return response.status;
                    })
                        .catch((e)=>{
                            addErrorClass(target);
                            console.error('Error:',e);
                            console.error('Action:',form.action);
                            console.error(formData);
                        });
                }else{
                    quantityInput.focus();
                    quantityInput.select();
                    quantityInput.setCustomValidity(GLOBALS.tc.pleaseEnterQuantity);
                    form.reportValidity();
                }

            }
            e.preventDefault();
        }

        if(target.closest(".js-addToInquiryBasketItemcard")){
            target=target.closest(".js-addToInquiryBasketItemcard");
            let form=target.closest("form");
            let orderButtonWrapper=target.closest(".orderButtonWrapper");
            let quantityInput=orderButtonWrapper.querySelector('.js-quantity-value');


            if(form&&quantityInput){
                if(quantityInput.value>0){
                    addLoadingClass(target);
                    let formData=new FormData(form);
                    let action = target.dataset.action;
                    formData.set('action',action);
                    XhrService.generalAjaxCall('enquiryCart',formData,'POST','json').then(response=>{
                        if(!response.status){
                            throw"Couldnotsavedata";
                        }
                        console.log(response);
                        addSuccessClass(target);
                        const headerBasket= document.querySelector('.js-headerEnquiryBasket');
                        if(headerBasket && response.headerEnquiryBasketContent){
                            var doc= new DOMParser().parseFromString(response.headerEnquiryBasketContent,"text/html");
                            updateHeaderEnquiryBasket(doc);
                        }
                        if(response.tagManager){
                            appendScriptToDOM(response.tagManager,true);
                        }
                        form.reset();

                        return response.status;
                    })
                        .catch((e)=>{
                            addErrorClass(target);
                            console.error('Error:',e);
                        });
                }
            }
            e.preventDefault();
        }


        if (target.closest(".js-removeFromBasketHover")) {
            target = target.closest(".js-removeFromBasketHover");

            addLoadingClass(target);
            // shop_remove_item_from_basket
            // data-actionid
            let formData = new FormData();
            formData.set('action', target.dataset.action);
            formData.set('action_id', target.dataset.actionid);
            formData.set('var_code', target.dataset.var_code);
            formData.set('customization_hash', target.dataset.customizationhash);


            XhrService.generalAjaxCall('cart', formData, 'POST', 'json').then(response => {
                addSuccessClass(target);
                if (!response.status) {
                    throw "Could not save data";
                }
                addSuccessClass(target);
                const headerBasket = document.querySelector('.js-headerBasket');
                if (headerBasket && response.headerBasketContent) {
                    var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                    updateHeaderBasket(doc);
                }
                if (response.tagManager) {
                    appendScriptToDOM(response.tagManager, true);
                }
                return response.status;
            })
                .catch((e) => {
                    addErrorClass(target);
                    console.error('Error:', e);
                });
            e.preventDefault();
        }

        if (target.closest(".js-addListItemToBasketAll")) {
            target = target.closest(".js-addListItemToBasketAll");
            let forms = document.querySelectorAll(".itemlist__body form");

            if (forms) {
                let formDatas = new FormData();
                for(let form of forms){
                    let qtyInput = form.querySelector('.js-quantity-value');
                    if(qtyInput){
                        if(parseInt(qtyInput.value) > 0){
                            let formData = new FormData(form);
                            for (let pair of formData.entries()) {
                                formDatas.append(pair[0], pair[1]);
                            }
                        }
                    }
                }
                addLoadingClass(target);
                formDatas.set('action', 'shop_add_all_items_to_basket');

                XhrService.generalAjaxCall('cart', formDatas, 'POST', 'json').then(response => {
                    if (!response.status) {
                        throw "Could not save data";
                    }
                    const headerBasket = document.querySelector('.js-headerBasket');
                    if (headerBasket && response.headerBasketContent) {
                        var doc = new DOMParser().parseFromString(response.headerBasketContent, "text/html");
                        updateHeaderBasket(doc);
                    }
                    if (response.tagManager) {
                        appendScriptToDOM(response.tagManager, true);
                    }
                    for(let form of forms){
                        form.reset();
                    }
                    updateItemlistStickyOrderBarCount();
                    addSuccessClass(target);
                    return response.status;
                })
                    .catch((e) => {
                        addErrorClass(target);
                        console.error('Error:', e);
                        console.error('Action:', form.action);
                        console.error(formDatas);
                    });

            }
            e.preventDefault();
        }

        if(target.closest(".js-addListItemToInquiryBasketAll")){
            target = target.closest(".js-addListItemToInquiryBasketAll");
            let forms = document.querySelectorAll(".itemlist__body form");

            if (forms) {
                let formDatas = new FormData();
                for(let form of forms){
                    let qtyInput = form.querySelector('.js-quantity-value');
                    if(qtyInput){
                        if(parseInt(qtyInput.value) > 0){
                            let formData = new FormData(form);
                            for (let pair of formData.entries()) {
                                formDatas.append(pair[0], pair[1]);
                            }
                        }
                    }
                }
                addLoadingClass(target);
                formDatas.set('action', 'shop_add_all_items_to_basket');

                XhrService.generalAjaxCall('enquiryCart',formDatas,'POST','json').then(response=>{
                    if(!response.status){
                        throw"Couldnotsavedata";
                    }
                    const headerBasket=document.querySelector('.js-headerEnquiryBasket');
                    if(headerBasket && response.headerEnquiryBasketContent){
                        var doc= new DOMParser().parseFromString(response.headerEnquiryBasketContent,"text/html");
                        updateHeaderEnquiryBasket(doc);
                    }
                    if(response.tagManager){
                        appendScriptToDOM(response.tagManager,true);
                    }
                    for(let form of forms){
                        form.reset();
                    }
                    updateItemlistStickyOrderBarCount();
                    addSuccessClass(target);
                    return response.status;
                })
                    .catch((e)=>{
                        addErrorClass(target);
                        console.error('Error:',e);
                        console.error('Action:',form.action);
                        console.error(formDatas);
                    });
            }
            e.preventDefault();
        }
    });
}

export function triggerBasketForms(){
    for(let basketForm of document.querySelectorAll(".js-basketSendForm")){
        basketForm.addEventListener("submit",function (e){
            let formData = new FormData(basketForm);
            let submitButton = basketForm.querySelector("button[type='submit']");
            if(submitButton){
                addLoadingClass(submitButton);
            }
            fetch(basketForm.getAttribute("action"),{
                method: "post",
                body: formData
            })
                .then(function(response) {
                    if(response.ok){
                        return response.text();
                    }
                })
                .then(function(data) {
                    window.location = window.location.pathname;
                })
                .catch(function() {
                    console.log(error);
                });
            e.preventDefault();
        });
    }
}

export function triggerUpdateBasketView() {
    let updateTimeout = false;
    let basketView = document.getElementById("userBasket");
    if (basketView) {
        eventListenerBasketViewQuantities(basketView, updateTimeout);
        eventListenerBasketViewQuantitySpinners(basketView, updateTimeout);
        eventListenerBasketViewReferenceInputs(basketView, updateTimeout);
    }
}