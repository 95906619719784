import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {clearRefinements, configure, hits, searchBox, stats} from "instantsearch.js/es/widgets";
import {connectCurrentRefinements, connectRefinementList} from "instantsearch.js/es/connectors";
import {initFiltersMobile} from "../shop/showFiltersMobile";
import {
    buildItemlistViewSwitch,
    convertBackValue,
    convertValue,
    escapeFilterValue,
    getBlockedCountryFilter,
    getCurrentAlgoliaTemplate,
    getCustomerCountryCode,
    getCustomHitsPerPage,
    getCustomPagination,
    getEmptyResultsText,
    hideFilter,
    hideListViewSwitch,
    showListView,
    showListViewSwitch,
    showSearchView,
    sortNumerically,
    updateHitValues
} from "./algoliaFunctions";
import {showModal} from "../../../default/global/js/modal";

let firstLoad = true;
let selectedFilterCounter = 0;

function buildListFilter(renderOptions){
    const {
        widgetParams
    } = renderOptions;

    const input = document.createElement('input');
    const ul = document.createElement('ul');

    let htmlWrapper = '<div class="ais-Panel"><div class="ais-Panel-header"><span>'+widgetParams.refinement.name+'</span></div><div class="ais-Panel-body"><div><div class="ais-RefinementList"><div class="ais-RefinementList-searchBox"><form action role="search" class="ais-SearchBox-form" novalidate><button class="ais-SearchBox-reset" type="reset"><svg class="ais-SearchBox-resetIcon" viewBox="0 0 20 20" width="10" height="10" aria-hidden="true"><path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path></svg></button></form></div></div></div></div></div>';
    let wrapper = new DOMParser().parseFromString(htmlWrapper, 'text/html');

    let refinementWrapper = wrapper.querySelector('.ais-Panel');
    if (refinementWrapper) {
        let refinementList = wrapper.querySelector('.ais-RefinementList');
        let formSearch = wrapper.querySelector('.ais-SearchBox-form');
        if (formSearch) {
            input.classList.add("ais-SearchBox-input");
            input.setAttribute("placeholder", widgetParams.searchablePlaceholder);

            let resetButton = formSearch.querySelector(".ais-SearchBox-reset");
            formSearch.prepend(input);

            if (resetButton) {
                toggleResetButton(formSearch);
            }
        }
        ul.classList.add("ais-RefinementList-list");
        refinementList.appendChild(ul);
    }

    widgetParams.container.appendChild(refinementWrapper);
}

function getTemplateRefinementListItem(item,url,type = 0,dataType = 0){
    let html = ``;
    let label = item.label;
    //if data type is not text
    if(dataType !== 4){
        label = convertValue(label);
    }
    switch (type){
        case 4:
            let newValue = escapeFilterValue(item.value);
            html = `<li class="ais-RefinementList-item${item.isRefined ? ' ais-RefinementList-item--selected' : ''}">
                  <div><label class="ais-RefinementList-label specialcheckbox">
                      <input type="checkbox" class="ais-RefinementList-checkbox" value='${newValue}' data-url="${url}">
                      <i></i>
                      <span class="ais-RefinementList-labelTextWrapper">
                          <span class="ais-RefinementList-labelText">${label}</span>
                          <span class="ais-RefinementList-count">(${item.count})</span>
                      </span>
                  </label></div>
                </li>
              `;
            break;
        default:
            html = `<li class="ais-RefinementList-item${item.isRefined ? ' ais-RefinementList-item--selected' : ''}">
                  <a
                    href="${url}"
                    data-value='${item.value}'
                    class="${item.isRefined ? 'active' : ''}"
                  >
                  <span class="ais-RefinementList-labelTextWrapper">
                      <span class="ais-RefinementList-labelText">${label}</span>
                      <span class="ais-RefinementList-count">(${item.count})</span>
                  </span>
                  <i class="icon icon-close ais-RefinementList-deleteRefinement"></i>
                  </a>
                </li>
              `;
            break;
    }

    return html;

}

function getCustomCurrentRefinements(options) {
    const createDataAttribtues = refinement =>
        Object.keys(refinement)
            .map(function (key){
                if(key === "value"){
                    let value = escapeFilterValue(refinement[key]);
                    return `data-${key}='${value}'`
                }else{
                    return `data-${key}='${refinement[key]}'`
                }
            })
            .join(' ');

    function generateRenderListItems(items) {
        let html = "";
        for (let item of items) {
            let dataType = 0;
            let filteredArray = refinementListArray.filter(function (itm) {
                return item.attribute.indexOf(itm.code) > -1;
            });
            if (filteredArray) {
                if (filteredArray[0] && filteredArray[0].dataType !== undefined) {
                    dataType = parseInt(filteredArray[0].dataType);
                }
            }

            for (let refinement of item.refinements) {
                let value = refinement.value;
                let newValue = value;
                if (dataType !== 4) {
                    newValue = convertValue(value);
                }else{
                    newValue = escapeFilterValue(value);
                }
                let label = refinement.attribute;
                if (GLOBALS.tc[refinement.attribute.toLowerCase()]) {
                    label = GLOBALS.tc[refinement.attribute.toLowerCase()];
                }
                if (refinementListArray) {
                    let obj = refinementListArray.find(o => o.code === refinement.attribute);
                    if (obj) {
                        label = obj.name;
                    }
                }
                if (refinement.attribute == 'NORMNO') {
                    label = 'DIN-ISO-ART';
                }
                html += `<li class="ais-CurrentRefinements-item" ${createDataAttribtues(refinement)}>${label}: ${newValue}</li>`;
            }
        }

        return html;
    }

    const renderCurrentRefinements = (renderOptions, isFirstRender) => {
        const {items, refine, widgetParams} = renderOptions;
        const container = widgetParams.container;

        if (container) {

            container.innerHTML = `<ul class="ais-CurrentRefinements-list">
              ${generateRenderListItems(items)}
            </ul>`;

            for (let element of container.querySelectorAll('.ais-CurrentRefinements-item')) {
                element.addEventListener('click', event => {
                    const item = Object.keys(event.currentTarget.dataset).reduce(
                        (acc, key) => ({
                            ...acc,
                            [key]: event.currentTarget.dataset[key],
                        }),
                        {}
                    );

                    refine(item);
                });
            }
            if (items.length > 0) {
                container.closest(".filterBox__selected").style.display = "";
                updateCategoryListViews(options,false);
            } else {
                container.closest(".filterBox__selected").style.display = "none";
                updateCategoryListViews(options,true);
            }

            selectedFilterCounter = items.length;
            options.selectedFilterCounter = selectedFilterCounter;
        }
    };

    return connectCurrentRefinements(
        renderCurrentRefinements
    );
}

function getRefinementList(search, refinement, itemsToDisplay) {
    const showMoreBtn = false;
    const alwaysSearchable = true;

    let name = refinement.name;

    if (refinement.code == 'NORMNO') {
        name = 'DIN-ISO-ART';
    }

    const renderCustomListSearchFilter = (renderOptions, isFirstRender) => {
        if (isFirstRender) {
            buildListFilter(renderOptions);
        }

        buildCustomSearchInteraction(renderOptions);
    };

    const customListSearchFilter = connectRefinementList(
        renderCustomListSearchFilter
    );

    return search.addWidgets([
        (customListSearchFilter({
                container: document.querySelector('.' + refinement.code + '_list'),
                attribute: refinement.code,
                operator: (refinement.multi == '1' ? 'and' : 'or'),
                limit: itemsToDisplay,
                showMore: showMoreBtn,
                showMoreLimit: 1000,
                searchableIsAlwaysActive: alwaysSearchable,
                searchable: true,
                searchablePlaceholder: GLOBALS.tc.filterSearch,
                searchStartWith: true,
                refinement: refinement,
                sortBy: sortNumerically
            })
        )
    ]);
}

function getFilterWidgets(search, refinement, itemsToDisplay) {
    switch (parseInt(refinement.displayType)){
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            getRefinementList(search, refinement, itemsToDisplay);
            break;
    }
}

function buildCustomSearchInteraction(renderOptions){
    const {
        items,
        isFromSearch,
        refine,
        createURL,
        searchForItems,
        widgetParams
    } = renderOptions;

    const input = widgetParams.container.querySelector('.ais-SearchBox-input');

    if (!isFromSearch && input.value) {
        input.value = '';
    }

    let currentItems = items;

    if (isFromSearch && input.value !== "" && widgetParams.searchStartWith) {
        let searchValue = input.value;
        searchValue = convertBackValue(searchValue);
        currentItems = items.filter((item) => item.value.startsWith(searchValue))
    }

    let displayType = 0;
    let dataType = 0;

    if(widgetParams.refinement && widgetParams.refinement.displayType){
        displayType = parseInt(widgetParams.refinement.displayType);
        dataType = parseInt(widgetParams.refinement.dataType);
    }

    widgetParams.container.querySelector('ul').innerHTML = currentItems.map(
        item => getTemplateRefinementListItem(item,createURL(item.value),displayType,dataType)
    ).join('');

    if (currentItems.length === 0) {
        widgetParams.container.querySelector('ul').innerHTML = "<li>"+GLOBALS.tc.noResult+"</li>";
    }

    let inputSearch = widgetParams.container.querySelector('.ais-SearchBox-input');
    let formSearch = widgetParams.container.querySelector('.ais-SearchBox-form');

    for(let element of widgetParams.container.querySelectorAll('a')){
        element.addEventListener('click', event => {
            event.preventDefault();
            refine(event.currentTarget.dataset.value);
        });
    }

    for(let element of widgetParams.container.querySelectorAll('.ais-RefinementList-checkbox')){
        element.addEventListener('click', event => {
            event.preventDefault();
            refine(element.value);
            if (formSearch) {
                toggleResetButton(formSearch);
            }
        });
    }

    if (formSearch){
        formSearch.addEventListener('submit', event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            let list = inputSearch.closest('.ais-RefinementList');
            let firstItem = list.querySelector(".ais-RefinementList-checkbox,.ais-RefinementList-item > a");
            if(firstItem){
                if(firstItem.tagName === "INPUT"){
                    refine(firstItem.value);
                }
                if(!formSearch.closest(".headerExpertSearch")){
                    if(firstItem.tagName === "A"){
                        firstItem.click();
                    }
                }
            }
        });
    }

    if(inputSearch) {
        inputSearch.addEventListener('keyup', event => {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            searchForItems(event.currentTarget.value);
            if (formSearch) {
                toggleResetButton(formSearch);
            }
        });

        let inputReset = widgetParams.container.querySelector('.ais-SearchBox-reset');

        if(inputReset){
            inputReset.addEventListener("click", function (event) {
                event.stopImmediatePropagation();
                event.preventDefault();
                event.stopPropagation();
                if(inputSearch){
                    inputSearch.focus();
                }
                inputReset.hidden = true;
                searchForItems("");
            });
        }
    }
}

function showCategoryView(options){
    if(options.categoryView){
        options.categoryView.style.display = "";
    }
}

function showSearchBar(searchInput) {
    let refinement = searchInput.closest(".ais-RefinementList");

    if (refinement) {
        let refinementItems = refinement.querySelectorAll('.ais-RefinementList-item');
        if (refinementItems) {
            if (refinementItems.length > 5) {
                searchInput.disabled = false;
            } else {
                searchInput.disabled = true;
            }
        }
    }
}

function showSearchBars() {
    for (let searchInput of document.querySelectorAll('.ais-SearchBox-input')) {
        showSearchBar(searchInput);
    }
}

function hideCategoryView(options){
    if(options.categoryView){
        options.categoryView.style.display = "none";
    }
}

function hideListView(options){
    options.listView.style.display = "none";
    hideListViewSwitch(options);
}

function hideEmptyRefinementLists() {
    for (let emptyRefinementList of document.querySelectorAll('.ais-filter__wrapper .ais-RefinementList--noRefinement')) {
        emptyRefinementList.closest('.ais-Panel').style.display = 'none';
    }
}

function hideEmptyFilters() {
    for (let filter of document.querySelectorAll('.ais-Panel')) {
        let hasItems = filter.querySelector(".ais-RefinementList-item");
        filter.parentNode.style.display = "";
        if(!hasItems){
            filter.parentNode.style.display = "none";
        }
    }
}

function scrollToTop(options){
    if(options.instantSearchWrapper){
        window.scrollTo({top: options.instantSearchWrapper.getBoundingClientRect().top + window.pageYOffset - document.getElementById('header').offsetHeight});
    }
}

function toggleResetButton(formSearch) {
    let resetButton = formSearch.querySelector(".ais-SearchBox-reset");
    if(resetButton){
        let input = formSearch.querySelector(".ais-SearchBox-input");
        let hiddenReset = false;
        if(input){
            if(input.disabled){
                hiddenReset = true;
            }
            if (input.value === "") {
                hiddenReset = true;
            } else {
                hiddenReset = false;
            }
        }
        resetButton.hidden = hiddenReset;
    }
}

function updateFilters(){
    hideEmptyFilters();
    hideEmptyRefinementLists();
    showSearchBars();
}

function updateCategoryListViews(options,showCategory = false){
    if(options.categoryView){
        if(showCategory){
            showCategoryView(options);
            hideListView(options);
            hideListViewSwitch(options);
        }else{
            showListView(options);
            showListViewSwitch(options);
            hideCategoryView(options);
        }
    }else{
        showListView(options);
        showListViewSwitch(options);
    }
}

export function initAlgoliaCategorySearch() {
    const options = {
        "instantSearchWrapper": document.getElementById("ais-InstantSearch"),
        "filterWrapper": document.getElementById("ais-InstantSearchFilter"),
        "hitsWrapper": document.getElementById("algoliaHitsList"),
        "clearRefinement": document.getElementById("algoliaClearAllRefinements"),
        "currentRefinements": document.getElementById("algoliaCurrentRefinements"),
        "numberOfHits": document.getElementById("algoliaNumberOfHits"),
        "hitsPerItems": document.getElementById("algoliaCountItemsPerPageSwitch"),
        "pagination": document.getElementById("algoliaPagination"),
        "hitsViewSwitch": document.getElementById("algoliaHitsViewSwitch"),
        "hitsView": document.getElementById("algoliaHitsView"),
        "categoryView": document.getElementById("algoliaCategoryView"),
        "listView": document.getElementById("algoliaListView"),
        "isCategoryView": false,
        "isSearch": false,
        "limit": 990,
        "tc": {
            "noResults": GLOBALS.tc.noResult,
            "foundItems": GLOBALS.tc.foundItems,
            "removeFilter": GLOBALS.tc.removeFilter,
            "itemsPerPage": GLOBALS.tc.itemsPerPage
        }
    };

    if(options.categoryView){
        options.isCategoryView = true;
    }

    if(document.body.classList.contains("search")){
        options.isSearch = true;
    }

    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

    const algoliaInstantSearch = instantsearch({
        indexName: algoliaIndexName,
        searchClient,
        routing: true,
        numberLocale: 'de',
        future: {
            preserveSharedStateOnUnmount: true,
        }
    });

    const customCurrentRefinements = getCustomCurrentRefinements(options);
    const customPagination = getCustomPagination();
    const customHitsPerPage = getCustomHitsPerPage();
    const algoliaHitTemplate = getCurrentAlgoliaTemplate();

    if (!algoliaHitTemplate) {
        return false;
    }

    initFiltersMobile();

    let filters = "";

    if(!options.isSearch){
        filters = "categoryPageId: '" + categoryToShow + "'";
    }

    const customerCountryCode = getCustomerCountryCode();
    if(customerCountryCode){
        let blockedCountryFilter = getBlockedCountryFilter(customerCountryCode);

        if(blockedCountryFilter !== false){
            if(!options.isSearch){
                filters += ` AND ${blockedCountryFilter}: 0`;
            }else{
                filters += `${blockedCountryFilter}: 0`;
            }
        }
    }

    algoliaInstantSearch.addWidgets([
        searchBox({
            container: "#hiddenSearchbox"
        }),
        configure({
            maxValuesPerFacet: 1000,
            filters: filters
        }),
        hits({
            container: options.hitsWrapper,
            templates: {
                empty(results, {html}) {
                    for(let item of document.querySelectorAll(".itemlist__header, .itemlist__footer, .itemAnnotation")){
                        item.style.display = "none";
                    }
                    if(options.isSearch){
                        hideFilter(options);
                    }
                    let emptyText = getEmptyResultsText(options);
                    return html`${emptyText}`;
                },
                item: algoliaHitTemplate
            }
        }),
        clearRefinements({
            container: options.clearRefinement,
            templates: {
                resetLabel({hasRefinements}, {html}) {
                    return html`<i class="icon icon-trash"></i><span>${options.tc.removeFilter}</span>`;
                },
            },
        }),
        stats({
            container: options.numberOfHits,
            templates: {
                text(data, {html}) {
                    let count = '';

                    if (data.hasManyResults) {
                        let countHits = data.nbHits;
                        if(paginationLimitedTo){
                            if(parseInt(countHits) > parseInt(paginationLimitedTo)) {
                                countHits = paginationLimitedTo;
                            }
                        }
                        count = `${countHits} ${options.tc.foundItems}`;
                    }

                    return html`<span>${count}</span>`;
                },
            },
        }),
        customHitsPerPage({
            container: options.hitsPerItems,
            items: [
                {label: '15 ' + options.tc.itemsPerPage, value: 15, default: true},
                {label: '50 ' + options.tc.itemsPerPage, value: 50},
                {label: '100 ' + options.tc.itemsPerPage, value: 100},
            ],
        }),
        customPagination({
            container: options.pagination
        }),
        customCurrentRefinements({
            container: options.currentRefinements
        })
    ]);

    refinementListArray.forEach((refinement) => {
        getFilterWidgets(algoliaInstantSearch, refinement, options.limit);
    });

    algoliaInstantSearch.start();

    buildItemlistViewSwitch(options);

    algoliaInstantSearch.on('render', () => {
        if (algoliaInstantSearch.status === "idle") {

            showSearchView();

            updateHitValues(options);

            updateFilters();

            if(!firstLoad){
                scrollToTop(options);
            }

            for (let loginModalTarget of options.instantSearchWrapper.querySelectorAll('.js-algoliaOpenLoginModal')) {
                loginModalTarget.addEventListener("click", function (e) {
                    showModal(document.getElementById(loginModalTarget.dataset.modal), function (modal) {
                        let hiddenSelectedItem = modal.querySelector('input[name="catalog_selected_item"]');
                        if (hiddenSelectedItem) {
                            hiddenSelectedItem.value = loginModalTarget.dataset.itemId;
                        }
                    });
                });
            }

            if(firstLoad){
                firstLoad = false;
                options.instantSearchWrapper.style.display = "";
            }
        }
    });
}