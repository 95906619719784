import {initTrustbars} from "../../../default/global/js/trustbar";
import {closeFilterBox, initFilter} from "../../../default/global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {initQuantitySpinners} from "../../../default/global/js/quantitySpinner";
import hoverintent from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal} from "../../../default/global/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../default/global/js/basket";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "../../../default/global/js/itemListSlider";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {initItemlistToggle} from "../../../default/global/js/itemlistToggle";
import {initPriceListGraduated} from "../../../default/global/js/priceListGraduated";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initFlashmessages} from "../../../default/global/js/flashmessages";
import {initPasswordFields} from "../../../default/global/js/password";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputBody} from "../../../default/global/js/inputfile";
import {initImageGallery} from "../../../default/global/js/imageGallery";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {elementViewportScroll} from "./custom/elementViewportScroll";
import {replaceMobileCartButton} from "./custom/replaceMobileCartButton";
import {showSearchBarMobile} from "../../../spGlobal/js/components/showSearchBarMobile";
import {initAlgoliaAutoComplete} from "../../../spGlobal/js/algolia/algoliaAutoComplete";
import {triggerFilegalleryFilter} from "../../../spGlobal/js/filegalleryFilter";
import {megaMenuHover} from "../../../spGlobal/js/megaMenu";
import {checkImagesAndLoadFallback} from "../../../spGlobal/js/checkImagesAndLoadFallback";
import {Tooltip} from "../../../default/global/js/tooltip";
import {disableContextMenuImages} from "../../../spGlobal/js/disableContextMenuImages";
import {initAlgoliaCategorySearch} from "../../../spGlobal/js/algolia/algoliaCategorySearch";
import {initAlgoliaExpertSearch} from "../../../spGlobal/js/algolia/algoliaExpertSearch";


window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            if (mobileMenuToggle.classList.contains('is-active')) {
                hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            } else {
                showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            }
        })

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }

    //algolia start
    let isExpertSearchView = false;

    if(document.getElementById("userSearch")) {
        if (document.getElementById("userSearch").classList.contains("isExpertSearch")) {
            isExpertSearchView = true;
        }
    }

    if(!isExpertSearchView){
        initAlgoliaCategorySearch();
    }

    initAlgoliaAutoComplete();

    initAlgoliaExpertSearch(isExpertSearchView);
    //algolia end

    initTrustbars();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    focusInputGroups();

    trimInputValues();

    initInputBody();

    initPasswordFields();

    initPhoneCountryFields();

    elementViewportScroll();

    replaceMobileCartButton();

    showSearchBarMobile();

    checkImagesAndLoadFallback('/userdata/images/basics/sp-noImage.png');

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });

    // searchAddress();

    //stickyHeader(stickyHeaderArea);

    // if (window.outerWidth < breakPointSM) {
    //     for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
    //         getChildrenClassForNestedItems(parent);
    //     }
    // }

    if (sendFavoriteByJS) {
        // triggerFavoritesBtns();
    }

    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerConfirmModalLink();

    initQuantitySpinners();

    initToggleSlide();

    initFlashmessages();
    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }

    megaMenuHover();

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initSlidecontents();

    initItemlistSlider(document.querySelectorAll('.itemboxList.swiper'),{
        breakpointItems: {
            xs: 1.7,
            sm: 3,
            md: 4,
            lg: 4,
            xlg: 5
        }
    });

    initCustomSelects();

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            event.preventDefault();
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }
    lottieLoader();

    initItemlistToggle();
    initPriceListGraduated();

    initInputMaskDate();

    triggerFilegalleryFilter();

    disableContextMenuImages();
});