import {XhrService} from "../../../default/global/ts/xhr.service";
import {updateItemlistStickyOrderBarCount} from "../shop/itemlistStickyOrderBar";
import {connectHitsPerPage, connectPagination} from "instantsearch.js/es/connectors";

function updateHitElementsByResponse(response,itemElement){
    if (response.price) {
        itemElement.querySelector('.js-include-prices').innerHTML = response.price;
    }
    if (response.item_link) {
        itemElement.querySelector('.itemlistElement__description').href = response.item_link;
    }
    if (response.promotion) {
        itemElement.querySelector('.itemlistElement__badges').innerHTML = response.promotion;
    }
    if (response.packageUnits) {
        itemElement.querySelector('.js-include-packaging').innerHTML = response.packageUnits;
    }
    if (response.inventory) {
        itemElement.querySelector('.js-include-inventory').innerHTML = response.inventory;
    }
    if (response.datasheetLink) {
        if(itemElement.querySelector('.js-datasheetLink')){
            itemElement.querySelector('.js-datasheetLink').href = response.datasheetLink;
            itemElement.querySelector('.js-datasheetLink').style.display = "";
        }
    }
    if (response.orderbutton) {
        itemElement.querySelector('.js-include-addon').innerHTML = response.orderbutton;
    }
    if (response.favorite) {
        itemElement.querySelector('.itemlistElement__actions').innerHTML = response.favorite;
    }
    if (response.reference_no) {
        if(itemElement.querySelector(".itemlistElement__item_no.itemlistElement__item_no--reference")){
            itemElement.querySelector(".itemlistElement__item_no.itemlistElement__item_no--reference").style.display = "";
        }
        itemElement.querySelector('.itemlistElement__item_no .reference_no').innerHTML = response.reference_no;
    }
    if (response.formatedItemNo) {
        itemElement.querySelector('.itemlistElement__item_no .js-ajax-item-no').innerHTML = response.formatedItemNo;
    }
}

function updateHitElementsByResponseForCatalog(response,itemElement){
    if (response.formatedItemNo) {
        itemElement.querySelector('.itemlistElement__item_no .js-ajax-item-no').innerHTML = response.formatedItemNo;
    }
}

export function showFilter(options){
    if(options.filterWrapper){
        options.filterWrapper.style.display = "";
    }
}

export function hideFilter(options){
    if(options.filterWrapper){
        options.filterWrapper.style.display = "none";
    }
}

export function getEmptyResultsText(options){
    let emptyText = options.tc.noResults;
    if(options.isSearch){
        let search_term = getSearchTerm();
        if(search_term !== ""){
            emptyText = emptyText + ' <q>' + search_term + '</q>';
        }
    }

    return emptyText;
}

export function getSearchTerm(){
    let search_term = document.getElementById('userSearch').getAttribute('data-search-term');
    if(search_term !== ""){
        return search_term;
    }else{
        return false;
    }
}

function updateViewHandler(options,hasItemElements){
    //category
    if(options.isCategoryView){
        if(hasItemElements){
            if(options.selectedFilterCounter > 0){
                showListView(options);
                showListViewSwitch(options);
            }
        }
        return;
    }

    //search
    if(options.isSearch){
        if(hasItemElements){
            showFilter(options);
            showListViewSwitch(options);
            showListView(options);
        }else{
            if(options.selectedFilterCounter === 0){
                hideFilter(options);
            }
            if(options.listView){
                options.listView.innerHTML = '<div class="ais-Hits ais-Hits--empty">' + getEmptyResultsText(options) + '</div>';
            }
            showListView(options);
        }
        return;
    }

    //list
    if (!hasItemElements) {
        if(options.listView){
            options.listView.innerHTML = '<div class="ais-Hits ais-Hits--empty">' + getEmptyResultsText(options) + '</div>';
        }
        showListView(options);
    }else{
        showListViewSwitch(options);
        showListView(options);
    }
}

function eventUpdateQuantityByPackagingUnit(packagingUnit,callback = function (){}){
    let itemQty = packagingUnit.closest('.itemlistElement').querySelector('[id^="input_item_quantity_"]');
    if(itemQty){
        switch (packagingUnit.dataset.type){
            case "over_pack":
            case "parcel":
            case "pallet":
                itemQty.value = packagingUnit.dataset.package;
                break;
        }
        callback();
    }
}

export function sortNumerically(a, b) {
    return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'});
}

export function convertValue(value){
    let regEx = /[^0-9.]+/;
    if(!regEx.test(value)){
        value = parseFloat(value).toString();
        value = value.replace(".", ',');
    }

    return value;
}

export function convertBackValue(value){
    let regEx = /[^0-9,]+/;
    if(!regEx.test(value)){
        value = value.replace(",", '.');
    }

    return value;
}

export function getCurrentAlgoliaTemplate() {
    let algoliaHitTemplateID = "algolia-hit-template-list";

    if (display && display === 'tile') {
        algoliaHitTemplateID = "algolia-hit-template-tile";
    }

    const algoliaHitTemplateElement = document.getElementById(algoliaHitTemplateID);

    if (algoliaHitTemplateElement) {
        return algoliaHitTemplateElement.innerHTML;
    } else {
        return false;
    }
}

export function showSearchView(){
    const searchView = document.querySelector(".searchView");
    if(searchView){
        searchView.classList.remove("is-loading");
    }
}

export function buildItemlistViewSwitch(options) {
    const algoliaHitsWrapper = options.hitsWrapper;
    const algoliaHitsViewSwitch = options.hitsViewSwitch;
    const algoliaHitsView = options.hitsView;

    if (algoliaHitsView) {
        algoliaHitsView.classList.add('list');

        if (algoliaHitsWrapper && algoliaHitsViewSwitch && algoliaHitsView) {
            let buttonTiles = algoliaHitsViewSwitch.querySelector('.icon-grid-kacheln');
            let buttonList = algoliaHitsViewSwitch.querySelector('.icon-grid-liste');

            if (display === 'tile') {
                buttonTiles.classList.add('active');
                algoliaHitsView.classList.add('tiles');
                algoliaHitsView.classList.remove('list');
            } else {
                buttonList.classList.add('active');
                algoliaHitsView.classList.add('list');
            }

            buttonTiles.addEventListener('click', function () {
                let display_type = 'tile';
                sessionStorage.setItem('display_type', JSON.stringify(display_type));
                location.reload();
            });

            buttonList.addEventListener('click', function () {
                let display_type = 'list';
                sessionStorage.setItem('display_type', JSON.stringify(display_type));
                location.reload();
            });
        }
    }
}

export function hideListViewSwitch(options){
    options.hitsViewSwitch.style.display = "none";
}

export function updateHitValues(options) {
    let item_list = [];
    let iteratorCount = 0;

    for (const itemElement of document.querySelectorAll(".js_ajax_price")) {
        item_list.push(itemElement.dataset.itemNo);
    }

    if (item_list.length > 0) {
        if (parseInt(GLOBALS.config.shopType) !== 3) {
            hideListViewSwitch(options);
            XhrService.timeout = 20000;
            XhrService.generalAjaxCall('getAlgoliaItemListRender', {
                "item_list": item_list,
                'counter': iteratorCount
            }, 'POST', 'json').then(response => {
                if (response) {
                    const response_item_key = Object.keys(response);
                    let hasItemElements = true;

                    for (let item_no of response_item_key) {
                        const itemElement = document.getElementById(item_no);
                        if (itemElement) {
                            if (response[item_no].show) {
                                hasItemElements = true;
                                try {
                                    updateHitElementsByResponse(response[item_no],itemElement);
                                } catch (error) {
                                    console.error(error);
                                }
                                itemElement.classList.remove("is-loading");
                            } else {
                                //itemElement.remove();
                            }
                        }
                    }

                    updateViewHandler(options,hasItemElements);
                }

                initUpdateQtyByPackagingUnitEventClick();
            });
        }else{
            XhrService.timeout = 20000;
            XhrService.generalAjaxCall('getAlgoliaItemListRender', {
                "item_list": item_list,
                'counter': iteratorCount
            }, 'POST', 'json').then(response => {
                if (response) {
                    const response_item_key = Object.keys(response);

                    for (let item_no of response_item_key) {
                        const itemElement = document.getElementById(item_no);
                        if (itemElement) {
                            if (response[item_no].show) {
                                try {
                                    updateHitElementsByResponseForCatalog(response[item_no],itemElement);
                                } catch (error) {
                                    console.error(error);
                                }
                            }
                        }
                    }
                }
            });
        }
        iteratorCount++;
    }
}

export function showListViewSwitch(options){
    options.hitsViewSwitch.style.display = "";
}

export function showListView(options){
    options.listView.style.display = "";
}

export function initUpdateQtyByPackagingUnitEventClick(wrapper = document){
    for(let packagingUnit of wrapper.querySelectorAll('.itemListElement .packagingUnits > div')){
        packagingUnit.addEventListener('click', function () {
            eventUpdateQuantityByPackagingUnit(packagingUnit,function (){
                updateItemlistStickyOrderBarCount();
            })
        });
    }
}

export function getCustomHitsPerPage() {
    const renderHitsPerPage = (renderOptions, isFirstRender) => {
        const {items, canRefine, refine, widgetParams} = renderOptions;
        const container = widgetParams.container;
        let selects = '';
        if (isFirstRender) {

            if (container.querySelector('select')) {
                selects = container.querySelector('select');
            } else {
                selects = document.createElement('select');
            }
            const select = selects;
            if (sessionStorage.getItem('algolia-itemsPerPage')) {
                refine(sessionStorage.getItem('algolia-itemsPerPage'));
            }
            select.addEventListener('change', event => {
                refine(event.target.value);
                sessionStorage.setItem('algolia-itemsPerPage', event.target.value);
            });

            container.appendChild(select);
        }

        const select = container.querySelector('select');

        select.hidden = !canRefine;

        select.innerHTML = `
        
    ${items.map(item => `<option  value="${item.value}" ${item.isRefined ? 'selected' : ''}>
            ${item.label}
          </option>`)
            .join('')}`;
    };

    return connectHitsPerPage(
        renderHitsPerPage
    );
}

export function getCustomPagination() {
    const renderPagination = (renderOptions) => {
        const {
            pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine, createURL,widgetParams
        } = renderOptions;
        const container = widgetParams.container;
        let textPages = GLOBALS.tc.pagesText;
        let textFrom = GLOBALS.tc.from;
        if (nbPages > 1) {
            updateItemlistStickyOrderBarCount();
            container.innerHTML = `
            <ul>
                ${!isFirstPage ? `
            
                <a
                        href="${createURL(currentRefinement - 1)}"
                        data-value="${currentRefinement - 1}"
                >
                    <i class="icon icon-angle-left"></i>
                </a>
                ` : ''}
                <span>
                  ${textPages} ${currentRefinement + 1} ${textFrom} ${nbPages}
                </span>
                ${!isLastPage ? `
            
                <a
                        href="${createURL(currentRefinement + 1)}"
                        data-value="${currentRefinement + 1}"
                >
                    <i class="icon icon-angle-right"></i>
                </a>
                ` : ''}
            </ul>
            `;

            [...container.querySelectorAll('a')].forEach(element => {
                element.addEventListener('click', event => {
                    event.preventDefault();
                    window.scrollTo({top: document.getElementById('userSearch').getBoundingClientRect().top + window.pageYOffset - document.getElementById('header').offsetHeight});
                    refine(event.currentTarget.dataset.value);
                });
            });
        } else {
            container.innerHTML = ``;
        }
    };
    return connectPagination(renderPagination);
}

export function getCustomerCountryCode(){
    if(document.body.dataset.customerCountryCode !== undefined){
        return document.body.dataset.customerCountryCode;
    }
    return false;
}

export function getBlockedCountries(){
    const blockedCountriesMap = new Set();

    blockedCountriesMap.add("block_AD");
    blockedCountriesMap.add("block_AM");
    blockedCountriesMap.add("block_BY");
    blockedCountriesMap.add("block_KG");
    blockedCountriesMap.add("block_KZ");
    blockedCountriesMap.add("block_RU");

    return blockedCountriesMap;
}

export function getBlockedCountryFilter(countryCode){
    const blockedCountriesMap = getBlockedCountries();
    for(let entry of blockedCountriesMap.values()){
        if(entry === "block_"+countryCode.toUpperCase()){
            return entry;
        }
    }
    return false;
}

export function escapeFilterValue(value){
    let newValue = value;
    try {
        newValue = value.replace(/'/g,'&#039;');
    }
    catch(err) {
        console.log(err);
    }

    return newValue;
}