let addedItems = [];

function toggleItemlistStickyOrderBar(show = false){
    const itemlistStickyOrderBar = document.getElementById("js-itemlistStickyOrderBar");
    if(itemlistStickyOrderBar){
        let footer = document.querySelector('.footer');
        if(show){
            itemlistStickyOrderBar.classList.add("is-active");
            if(footer){
                footer.style.marginBottom = itemlistStickyOrderBar.offsetHeight + "px";
            }
        }else{
            itemlistStickyOrderBar.classList.remove("is-active");
            if(footer){
                footer.style.marginBottom = "";
            }
        }
    }
}

export function updateItemlistStickyOrderBarCount(){
    let allQty = 0;
    const counter = document.getElementById('js-itemlistStickyOrderBarCount');

    if(counter){
        for(let qty of document.querySelectorAll('.js-quantity-value')){
            let value = parseInt(qty.value);
            if(value > 0){
                allQty = allQty + 1;
            }
        }
        counter.innerText = allQty;
    }

    if(allQty > 0){
        toggleItemlistStickyOrderBar(true);
    }else{
        toggleItemlistStickyOrderBar(false);
    }
}