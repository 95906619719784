function checkImage(url) {
    const imagePromise = new Promise(function(resolve, reject) {
        let image = new Image();

        image.addEventListener('load', () => {
            resolve();
        });

        image.addEventListener('error', () => {
            reject();
        });

        image.src = url;
    });

    return imagePromise;
}

export function checkImagesAndLoadFallback(fallbackImageUrl) {
    for (let currentImage of document.querySelectorAll('.mainContent img')) {
        checkImage(currentImage.src).then(function() {
        }).catch(function() {
            currentImage.src = fallbackImageUrl;
        });
    }
}