import hoverintent from "../../default/global/js/hoverintent";

export function megaMenuHover(){
    for (let el of document.querySelectorAll('.js-mainNavigation ul li')) {
        let megamenuContainer = el.closest('.megaMenu__navigation');
        hoverintent(el,200,function (){
            if(el.classList.contains("level_2")){
                let ul = el.querySelector('ul');
                let newHeight = 0;
                if(ul.classList.contains("level_3")){
                    newHeight = ul.offsetHeight;
                }
                if(megamenuContainer){
                    megamenuContainer.style.minHeight = newHeight;
                }
            }
        },function (){
            if(el.classList.contains("level_2")){
                let ul = el.querySelector('ul');
                if(ul.classList.contains("level_3")){
                    if(megamenuContainer){
                        megamenuContainer.style.minHeight = "";
                    }
                }
            }
        });
    }
}