import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';

import '@algolia/autocomplete-theme-classic';
import {getBlockedCountryFilter, getCustomerCountryCode} from "./algoliaFunctions";

export function initAlgoliaAutoComplete() {
    const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);
    const searchSkeleton = document.getElementById('algoliaSearchboxSkeleton');

    let filters = "";

    const customerCountryCode = getCustomerCountryCode();
    if(customerCountryCode){
        let blockedCountryFilter = getBlockedCountryFilter(customerCountryCode);

        if(blockedCountryFilter !== false){
            filters = `${blockedCountryFilter}: 0`;
        }
    }
    autocomplete({
        container: '.headerMain .autocomplete__search',
        panelContainer: '.headerMain .autocomplete__results',
        placeholder: searchPlaceHolder,
        detachedMediaQuery: 'none',
        onSubmit(params) {
            let query = params.state.query;
            if(query.trim() !== ""){
                location.href = `${customizedUrl}/search/?${algoliaIndexName}%5Bquery%5D=${query}&searchAlgolia=${query}`;
            }
        },
        getSources() {
            return [{
                sourceId: 'items',
                getItems({query}) {
                    return getAlgoliaResults({
                        searchClient, queries: [{
                            indexName: algoliaIndexName,
                            query,
                            params: {
                                hitsPerPage: 5,
                                filters: filters
                            },
                        },],
                    });
                },
                templates: {
                    item({item, components, html}) {
                        return html`
                            <a href="${customizedUrl+item.itemLink}" class="autocomplete__item" id="autocomplete_${item.itemNo}">
                                <div>
                                    <span class="autocomplete__name">
                                        ${components.Highlight({
                                            hit: item, attribute: 'description',
                                        })}
                                    </span>
                                    <span class="autocomplete__itemNumber">
                                        ${components.Highlight({
                                            hit: item, attribute: 'itemNo',
                                        })}
                                    </span>
                                </div>
                                <span class="autocomplete__summary">
                                    ${components.Highlight({
                                        hit: item, attribute: 'itemSummary',
                                    })}
                                </span>
                            </a>
                        `;
                    },
                }
            },];
        },
    });
    autocomplete({
        container: '.mobileSearch .autocomplete__search',
        panelContainer: '.mobileSearch .autocomplete__results',
        placeholder: searchPlaceHolder,
        detachedMediaQuery: 'none',
        onSubmit(params) {
            let query = params.state.query;
            if(query.trim() !== ""){
                location.href = `${customizedUrl}/search/?${algoliaIndexName}%5Bquery%5D=${query}&searchAlgolia=${query}`;
            }
        },
        getSources({query}) {
            return [{
                sourceId: 'items',
                getItems() {
                    return getAlgoliaResults({
                        searchClient, queries: [{
                            indexName: algoliaIndexName,
                            query,
                            params: {
                                hitsPerPage: 5,
                                filters: filters
                            },
                        },],
                    });
                },
                templates: {
                    item({item, components, html}) {
                        return html`
                            <a href="${customizedUrl+item.itemLink}" class="autocomplete__item" id="autocomplete_${item.itemNo}">
                                <span class="autocomplete__name">
                                    ${components.Highlight({
                            hit: item, attribute: 'description',
                        })}
                                </span>
                                <span class="autocomplete__itemNumber">
                                    ${components.Highlight({
                            hit: item, attribute: 'itemNo',
                        })}
                                </span>
                            </a>
                        `;
                    },
                }
            },];
        },
    });

    if(searchSkeleton){
        searchSkeleton.innerHTML = '';
        searchSkeleton.classList.add('hidden');
    }

    noFocusHideResults();
}

function noFocusHideResults() {
    let searchInputWrapper = document.querySelector('.headerMain .autocomplete__wrapper');
    let searchInput = searchInputWrapper.querySelector('.autocomplete__search input');
    let searchResults = searchInputWrapper.querySelector('.autocomplete__results');

    if (searchInputWrapper && searchInput) {
        searchInput.addEventListener('focus', function() {
            searchInputWrapper.classList.add('hasFocus');
        });

        searchInput.addEventListener('blur', function() {
            searchInputWrapper.classList.remove('hasFocus');
        });
    }

    if (searchInput && searchResults) {
        searchInput.addEventListener('keyup', function() {
            let selectedSearchResultLink = searchInputWrapper.querySelector('.autocomplete__results li[aria-selected="true"] a');

            if (selectedSearchResultLink) {
                searchInput.addEventListener('keyup', function(e) {
                    if (e.keyCode === 13) {
                        selectedSearchResultLink.focus();
                        window.location.replace(selectedSearchResultLink.href);
                    }
                });
            }
        });
    }
}