
function checkAvailabilityDotStatus(availabilityDot,value = 0){
    let inventory = availabilityDot.dataset.inventory;
    if (inventory) {
        inventory = parseInt(inventory);
        if (value > inventory) {
            availabilityDot.classList.remove("availabilityDot--green");
            availabilityDot.classList.add("availabilityDot--red");
        } else {
            availabilityDot.classList.add("availabilityDot--green");
            availabilityDot.classList.remove("availabilityDot--red");
        }
    }
}

export function setDot(inputQuantity){
    let wrapper = inputQuantity.closest(".itemBasketBox,.easyOrderBox");
    if(wrapper) {
        let availabilityDot = wrapper.querySelector('.js-availability-dot');
        if (availabilityDot) {
            checkAvailabilityDotStatus(availabilityDot,parseInt(inputQuantity.value));
        }
    }
}

export function setAllDots(){
    for(let quantityInput of document.querySelectorAll('.js-quantity-value')){
        setDot(quantityInput);
    }
}