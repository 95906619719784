export function showFiltersMobile() {
    initFiltersMobile();
}

export function initFiltersMobile() {
    let mobileFilterHidden = true;
    const body = document.querySelector("body");
    const toggleMobileFilterButtons = document.querySelectorAll('.ais-mobileFilterToggle');
    const filterWrapper = document.getElementById('ais-filter');
    const filterParentWrapper = filterWrapper?.parentNode;
    const backdrop = document.createElement('div');
    backdrop.classList.add('modalBg');
    backdrop.style.display = 'flex';
    backdrop.style.opacity = '1';

    function toggleFilter() {
        if (!filterWrapper || !backdrop) {
            console.error('Filter wrapper or backdrop not found.');
            return;
        }
        body.classList.toggle('ais-filter--bodyBackdrop');
        filterWrapper.classList.toggle('ais-filter--active');

        if (mobileFilterHidden) {
            body.appendChild(filterWrapper);
            body.appendChild(backdrop);
        } else {
            body.removeChild(backdrop);
            filterParentWrapper.appendChild(filterWrapper);
        }

        mobileFilterHidden = !mobileFilterHidden;
    }

    backdrop.addEventListener('click', toggleFilter);

    const submitMobileFilter = document.getElementById('ais-submitFilter');
    if (submitMobileFilter) {
        submitMobileFilter.addEventListener('click', toggleFilter);
    }

    if (toggleMobileFilterButtons) {
        toggleMobileFilterButtons.forEach(toggleMobileFilterButton => {
            toggleMobileFilterButton.addEventListener('click', toggleFilter);
        });
    }
}