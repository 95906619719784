export function showSearchBarMobile() {
    let searchBarTrigger = document.querySelector('.mobileLink--search');
    let mobileSearchBar = document.querySelector('.headerAccount__mobile .mobileSearch');
    let mobileSearchBarInput = document.querySelector('.headerAccount__mobile .mobileSearch input');

    if(searchBarTrigger) {
        searchBarTrigger.addEventListener('click', function() {
            mobileSearchBar.classList.toggle('js-showSearchBar');
            mobileSearchBarInput.focus();
        });
    }
}