import Swiper from 'swiper';
import {Autoplay, Navigation} from 'swiper/modules';

export function initScrollbars(query = document.querySelectorAll('.scrollbar--default .scrollbar')) {
    for (let scrollbar of query) {
        Swiper.use([Autoplay, Navigation]);
        new Swiper(scrollbar, {
            spaceBetween: 24,
            rewind: true,
            navigation: {
                nextEl: scrollbar.parentElement.querySelector('.swiper-button-next'),
                prevEl: scrollbar.parentElement.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 4,
                },
                850: {
                    slidesPerView: 5,
                },
                1024: {
                    slidesPerView: 6,
                },
                1250: {
                    slidesPerView: 7,
                },
                1540: {
                    slidesPerView: 8,
                }
            }
        });
    }
}

export function initAlternativeScrollbars(query = document.querySelectorAll('.scrollbar--alternative .scrollbar')) {
    for (let scrollbar of query) {
        scrollbar.setAttribute('dir', 'rtl');
        Swiper.use([Autoplay, Navigation]);
        new Swiper(scrollbar, {
            spaceBetween: 24,
            rewind: true,
            navigation: {
                nextEl: scrollbar.parentElement.querySelector('.swiper-button-next'),
                prevEl: scrollbar.parentElement.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                },
                768: {
                    slidesPerView: 2.3,
                },
                1024: {
                    slidesPerView: 1.3,
                },
                1250: {
                    slidesPerView: 1.9,
                }
            }
        });

        let imageHeight = scrollbar.querySelector('.scrollbar__img').getBoundingClientRect().height;
        let swiperButtons = scrollbar.parentElement.querySelector('.swiper__buttons');
        let swiperButtonsHeight = swiperButtons.getBoundingClientRect().height;
        let buttonsOffsetTop = imageHeight - swiperButtonsHeight;
        swiperButtons.style.top = buttonsOffsetTop + 'px';

        let swiperSlideArray = scrollbar.querySelectorAll('.swiper-slide');
        swiperSlideArray.forEach((swiperSlide) => {
            swiperSlide.addEventListener('click', function() {
                if(swiperSlide.classList.contains('swiper-slide-active')) {
                    swiperSlide.classList.toggle('showDescription');
                }
            });
            let swiperButtonsArray = swiperButtons.querySelectorAll('div');
            swiperButtonsArray.forEach((swiperButton) => {
                swiperButton.addEventListener('click', function() {
                    swiperSlide.classList.remove('showDescription');
                });
            });
        })
    }
}
